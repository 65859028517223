import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Category } from '../../models';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'store-child-categories',
  styleUrls: ['./child-categories.component.scss'],
  templateUrl: './child-categories.component.html',
})
export class ChildCategoriesComponent {
  @Input('categories') categories: Array<Category>;

  constructor(private router: Router, private searchService: SearchService) {
  }

  searchProductsByCategory(category:number): void {
    //this._selectedCategoryId = category;
    this.searchService.setSearchPage(1);
    this.searchService.setSearch(1, category.toString());
  }
}