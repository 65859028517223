import { NgModule } from '@angular/core';
import { NewDetailComponent } from './new-detail.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NewDetailComponent
  ],
})
export class NewDetailModule { }
