import { Directive, Output, EventEmitter, HostListener, ElementRef, OnDestroy 
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[scroll]'
})
export class ScrollEventDirective implements OnDestroy {
  @Output() scrollPosition: EventEmitter<number> = new EventEmitter<number>
  ();

  private scrollEvent$;

  constructor(private el: ElementRef) {
    this.scrollEvent$ = fromEvent(window, "scroll").subscribe(e => {
        this.scrollPosition.emit(window.scrollY);    
    });
  }

  ngOnDestroy() {
    this.scrollEvent$.unsubscribe();
  }
}