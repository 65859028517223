import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './pages/home/home.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { HowToBuyComponent } from './pages/how-to-buy/how-to-buy.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { NewsComponent } from './pages/news/news.component';
import { NewDetailComponent } from './pages/new-detail/new-detail.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { ProductsComponent } from './pages/products/products.component';
import { BrandsComponent } from './pages/brands/brands.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'pages/home', component: HomeComponent },
  { path: 'pages/about-us', component: AboutUsComponent },
  { path: 'pages/checkout', component: CheckoutComponent },
  { path: 'pages/gallery', component: GalleryComponent },
  { path: 'pages/brands', component: BrandsComponent },
  { path: 'pages/how-to-buy', component: HowToBuyComponent },
  { path: 'pages/terms-conditions', component: TermsConditionsComponent },
  { path: 'pages/contact', component: ContactComponent },
  { path: 'pages/news', component: NewsComponent },
  { path: 'pages/new-detail/:id', component: NewDetailComponent },
  { path: 'pages/product-detail/:id', component: ProductDetailComponent },
  { path: 'pages/products', component: ProductsComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
  ]
})
export class AppRoutingModule { }