/* tslint:disable:member-ordering */
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[textHighlight]'
})
export class TextHighlightDirective {

  constructor(private el: ElementRef) { }

  @Input() defaultColor: string;

  @Input('textHighlight') highlightColor: string;

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.highlightColor || this.defaultColor || 'red', 'bolder');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null, null);
  }

  private highlight(color: string, fontWeight: string) {
    this.el.nativeElement.style.color = color;  
    this.el.nativeElement.style.fontWeight = fontWeight;  
  }
}