export class Customer {
    id: number;
    name: string;
    address: string;
    cP3: string;
    cP4: string;
    cp7: string;
    city: string;
    email: string;
    phone: string;
    nif: string;
}
