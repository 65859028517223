import { Component } from '@angular/core';

@Component({
  selector: 'sadofly-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  private expanded:boolean = true;
  private haspopup:boolean = true;

  constructor() {}

}