import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperNavigationInterface, SwiperPaginationInterface, SwiperKeyboardInterface, SwiperScrollbarInterface, SwiperAutoplayInterface } from 'ngx-swiper-wrapper';
import { Post } from '../../models';

@Component({
  selector: 'ngx-news-slider',
  templateUrl: './news-slider.component.html',
})
export class NewsSliderComponent implements AfterViewInit {
    public index: number = 0;

    public scrollConfig: SwiperScrollbarInterface = {
      draggable: true,
      hide: false,
      snapOnRelease: false
    };
  
    public keyboardConfig: SwiperKeyboardInterface = {
      enabled: true
    };
  
    public paginationConfig: SwiperPaginationInterface = {
      clickable: true,
      currentClass: '.swiper-pagination',
    }

    public autoplayConfig: SwiperAutoplayInterface = {
        delay: 5000,
        disableOnInteraction: false,
        stopOnLastSlide: false
    }

    public navigationConfig: SwiperNavigationInterface = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    
    public config: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 1,
        centeredSlides: false,
        keyboard: this.keyboardConfig,
        scrollbar : this.scrollConfig,
        mousewheel: false,
        pagination: this.paginationConfig,
        //navigation: this.navigationConfig,
        autoplay: this.autoplayConfig,
        loop: true,
        height: 300,
        watchSlidesProgress: true
    };
    
  @Input('news') _news: Array<Post>; 
  @ViewChild(SwiperComponent) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
        this.componentRef.directiveRef.update();
      }, 10000);
  }
}