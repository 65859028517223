import { Component, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Product, Manufacturer, Post, SearchInput, Session, Navigation } from '../../models';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { ManufacturerService } from '../../services/manufacturer.service';
import { SearchService } from '../../services/search.service';
import { PostService } from '../../services/post.service';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';
import { BaseService } from '../../services/base.service';

@Component({
  selector: 'store-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseService implements OnDestroy {
  private _newProducts:Array<Product> = [];
  private _featuredProducts:Array<Product> = [];
  private _manufacturers:Array<Manufacturer> = [];
  private _newsBanner:Array<Post> = [];
  private _mobile:boolean = false;
  private _tablet:boolean = false;
  private _pc:boolean = false;

  private imgBrands:string = this.baseUrl + "/assets/images/banners/brands.png";
  private imgHott:string = this.baseUrl + "/assets/images/banners/hott.png";
  private imgSadofly: string = this.baseUrl + "/assets/images/banners/sadofly.png";
  private imgHolidays: string = this.baseUrl + "/assets/images/banners/holidays.png";

  private sessionSubscription: Subscription = null;
  private navigationSubscription: Subscription = null;
  private bannersSubscription: Subscription = null;
  private recentProductsSubscription: Subscription = null;
  private featuredProductsSubscription: Subscription = null;
  private manufacturersSubscription: Subscription = null;

  constructor(private router: Router, 
              private productService: ProductService,
              private searchService: SearchService,
              private manufacturerService: ManufacturerService,
              private postService: PostService,
              private sessionService: SessionService) { 
                super();
                this.checkScreenSize();
              }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() : void {
    this._mobile = (window.screen.width < 420);
    this._tablet = (window.screen.width >= 420 && window.screen.width < 830);
    this._pc = (window.screen.width >= 830);
  }

  ngOnInit() {

    this.updateSessionInfo();
    this.checkScreenSize();

    this._newProducts = this.productService.getRecentProductsCache();
    this._featuredProducts = this.productService.getFeaturedProductsCache();
    this._manufacturers = this.manufacturerService.getFrontendManufacturersCache();

    // TEMP
    let banners: Array<Post> = this.postService.getBannersFromCache();

    // **********************************************************
    // Temporary fix for holidays
    // **********************************************************
    let brandsBanner: Post = new Post();
    brandsBanner.coverImage = this.imgBrands;
    banners.push(brandsBanner);

    let hottBanner: Post = new Post();
    hottBanner.coverImage = this.imgHott;
    banners.push(hottBanner);

    let sadoflyBanner: Post = new Post();
    sadoflyBanner.coverImage = this.imgSadofly;
    banners.push(sadoflyBanner);

    /*let holidaysBanner: Post = new Post();
    holidaysBanner.coverImage = this.imgHolidays;
    banners.push(holidaysBanner);*/

    // **********************************************************

    this._newsBanner = banners;

    if(banners.length == 0) {
      this.bannersSubscription = this.postService.getBanners().subscribe(data => {
        this._newsBanner = data.object; 
        this.postService.setBannersToCache(this._newsBanner);
      });
    }
    else {
      this._newsBanner = banners;
    }

    if(this._newProducts.length == 0) {
      let searchParameters:SearchInput = new SearchInput();
      searchParameters.page = 1;
      searchParameters.rows = 24;

      this.recentProductsSubscription = this.productService.getRecentProducts(searchParameters).subscribe((data: any) => {
        this._newProducts = data.object.products;
        this.productService.setRecentProductsCache(this._newProducts);
      });
    }

    if(this._featuredProducts.length == 0) {
      this.featuredProductsSubscription = this.productService.getFeaturedProducts().subscribe((data: any) => {
        this._featuredProducts = data.object.products;
        this.productService.setFeaturedProductsCache(this._featuredProducts);
      });
    }
    
    if(this._manufacturers.length == 0) {
      this.manufacturersSubscription = this.manufacturerService.getFrontendManufacturers().subscribe((data: any) => {
        this._manufacturers = data.object; 
        this.manufacturerService.setFrontendManufacturersCache(this._manufacturers);
      });
    }

    window.scrollTo(0,0);
  }

  goToPost(postId:number): void {
    this.router.navigate(['//pages/new-detail', postId]);
  }

  goToNewProductsPage(): void {
    this.searchService.setSearchPage(1);
    this.searchService.setSearch(3, "");
    this.router.navigate(['//pages/products']);
  }

  updateSessionInfo() : void {
    let session:Session = this.sessionService.getSessionInfo();

    if(session == null) {
      this.sessionSubscription = this.sessionService.createSessionObject().subscribe(data => {
         this.sessionService.setSessionIdentifier(data.object);
         this.updateNavigation();
      });
    }
    else {
      this.updateNavigation();
    }
  }

  updateNavigation() : void {
    let navigation:Navigation = new Navigation();
    navigation.page = "home";
    this.navigationSubscription = this.sessionService.addNavigationStep(navigation).subscribe(data => { });
  }

  ngOnDestroy()
  {
    if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
    if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
    if(this.bannersSubscription != null) this.bannersSubscription.unsubscribe();
    if(this.manufacturersSubscription != null) this.manufacturersSubscription.unsubscribe();
    if(this.recentProductsSubscription != null) this.recentProductsSubscription.unsubscribe();
    if(this.featuredProductsSubscription != null) this.featuredProductsSubscription.unsubscribe();
  }
}
