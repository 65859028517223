import { NgModule } from '@angular/core';
import { ContactComponent } from './contact.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCNb4MWEFJnvvu8dm1LMP8aelkOMbh2f0w'
    })
  ],
  declarations: [
    ContactComponent
  ],
})
export class ContactModule { }
