import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ProductCardComponent, HeaderComponent, FooterComponent, ChildCategoriesComponent } from './components';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { BottomHeaderComponent } from './components/bottom-header/bottom-header.component';
import { ScrollEventDirective } from './directives/scroll.drective';
import { ProductSwiperComponent } from './components/product-swiper/product-swiper.component';
import { NewsSliderComponent } from './components/news-slider/news-slider.component';
import { ExtendedProductCardComponent } from './components/extended-product-card/extended-product-card.component';
import { HighlightDirective } from './directives/highlight.directive';
import { TextHighlightDirective } from './directives/textHighlight.directive';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManufacturerSwiperComponent } from './components/manufacturer-swiper/manufacturer-swiper.component';
import { CategoriesGridComponent } from './components/categories-grid/categories-grid.component';

const BASE_MODULES = [
    CommonModule, 
    RouterModule, 
    FormsModule, 
    SwiperModule, 
    BrowserModule, 
    BrowserAnimationsModule,
    DeviceDetectorModule
];

const COMPONENTS = [
  ExtendedProductCardComponent,
  ProductCardComponent,
  HeaderComponent,
  FooterComponent,
  TopHeaderComponent,
  BottomHeaderComponent,
  ChildCategoriesComponent,
  ProductSwiperComponent,
  ManufacturerSwiperComponent,
  NewsSliderComponent,
  CategoriesGridComponent
];

const PIPES = [];

const DIRECTIVES = [ 
    ScrollEventDirective, 
    HighlightDirective,
    TextHighlightDirective 
];

@NgModule({
    imports: [...BASE_MODULES],
    exports: [...BASE_MODULES, ...COMPONENTS, ...PIPES],
    declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
})

export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
        };
    }
}
