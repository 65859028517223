export class Store {
  address: string;
  country: string;
  email: string;
  facebook: string;
  fax: string;
  google: string;
  instagram: string;
  linkedin: string;
  name: string;
  phone: string;
  postalcode: string;
  saturdaySchedule: string;
  sundaySchedule: string;
  skype: string;
  twitter: string;
  weekSchedule: string;
  youtube: string;
  lat: number;
  lng: number;
}
