import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Session, Navigation } from '../models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BaseService } from './base.service';

@Injectable()
export class SessionService extends BaseService {

  private _session:Session;

  constructor(private http: HttpClient, private deviceService: DeviceDetectorService) { 
    super();
  }

  getSessionInfo(): Session {
    return this._session;
  }

  createSessionObject() : any {
    this._session = this.retrieveDeviceInfoIntoSession();
    return this.http.post(this.baseUrl + '/api/session/Create', this._session);
  }

  setSessionIdentifier(sessionId: string) : void {
    this._session.sessionGuid = sessionId;
  }

  private retrieveDeviceInfoIntoSession() : Session {
    let deviceInfo = this.deviceService.getDeviceInfo();
    let session = new Session();
    session.browser = deviceInfo.browser;
    session.device = deviceInfo.device;
    session.os = deviceInfo.os;
    session.isDesktop = this.deviceService.isDesktop();
    session.isMobile = this.deviceService.isMobile();
    session.isTablet = this.deviceService.isTablet();
    return session;
  }

  addNavigationStep(navigation:Navigation) : any {
    navigation.sessionId = this._session.sessionGuid;
    return this.http.post(this.baseUrl + '/api/session/Navigation', navigation);
  }
}