import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Promise} from 'es6-promise';
import { HttpResponse, Category, Product, SearchInput, TreeNode, CacheCategory } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class ProductService extends BaseService {

  private _featuredProducts: Array<Product> = [];
  private _recentProducts: Array<Product> = [];
  private _relatedProducts: Product[];
  private _productsCache: Product[] = [];
  private _categoriesTree: Category[];
  private _categoriesCache: CacheCategory[] = new Array<CacheCategory>();
  private _treeNodes: TreeNode[];

  constructor(private http: HttpClient) { 
      super();
  }

  getMenuCategories(): any {
    return this.http.get<HttpResponse>(this.baseUrl + '/api/category/GetMenuCategories');
  }

  getCategoriesTree(): any {
    return this.http.get<HttpResponse>(this.baseUrl + '/api/category/Get');
  }
  
  getCategoriesTreeFromCache(): Category[] {
      return this._categoriesTree;
  }

  setCategoriesTreeInCache(tree: Category[]) : void {
    this._categoriesTree = tree;
  }

  // DEPRECATED
    drawCategoriesTree() : void {
        for (let i = 0; i < this._categoriesTree.length; i++) {
        const node:TreeNode = new TreeNode();
        node.id = this._categoriesTree[i].id;
        node.name = this._categoriesTree[i].name;
        node.level = 1;
        this._treeNodes.push(node);

        this.addCategoryChilds(this._categoriesTree[i].childs, 2);
        }
    }

    // DEPRECATED
    addCategoryChilds(categories: Array<Category>, level: number) : void {
        for (let i = 0; i < categories.length; i++) {
        const node:TreeNode = new TreeNode();
        node.id = categories[i].id;
        node.name = categories[i].name;
        node.level = level;
        this._treeNodes.push(node);
        
        if(categories[i].childs.length > 0) this.addCategoryChilds(categories[i].childs, level + 1);
        }
    }
  
    getRelatedProducts(searchInput: SearchInput): any {
        return this.http.post(this.baseUrl + '/api/search/SearchRelatedProducts', searchInput); 
    }


    getProduct(productId: number): any {
        return this.http.get(this.baseUrl + '/api/search/product/' + productId);
    }

    getProductFromCache(productId:number) : Product {
        let cachedProduct : Product = null;
        for(let i=0; i < this._productsCache.length; i++) {
            if(this._productsCache[i].id == productId) {
                cachedProduct = this._productsCache[i];
                break;
            }
        }
        return cachedProduct;
    }
    
    setProductInCache(product:Product) : void {
        let productExists:boolean = false;
        for(let i=0; i < this._productsCache.length; i++) {
            if(this._productsCache[i].id == product.id) {
                productExists = true;
                break;
            }
        }

        if(!productExists) {
            this._productsCache.push(product);
        }
    }

    // Recent Products

    getRecentProducts(searchParameters:SearchInput): any {
        return this.http.post(this.baseUrl + '/api/search/SearchRecentProducts', searchParameters);
    }

    getRecentProductsCache() : Array<Product> {
        return this._recentProducts;
    }
    
    setRecentProductsCache(recentProducts:Array<Product>) : void {
        this._recentProducts = recentProducts;
    }

    // Featured Products

    getFeaturedProducts(): any {
        return this.http.get(this.baseUrl + '/api/search/SearchFeaturedProducts'); 
    }

    getFeaturedProductsCache() : Array<Product> {
        return this._featuredProducts;
    }
    
    setFeaturedProductsCache(featuredProducts:Array<Product>) : void {
        this._featuredProducts = featuredProducts;
    }
    
    // Product Breadcrumb info
    getBreadcrumbInfo(productId: number): any {
        return this.http.get(this.baseUrl + '/api/category/GetUpstreamCategoriesByProduct/' + productId); 
    }

    getBreadcrumbInfoFromCache(productId:number) : Category {
        let cachedCategory : Category = null;
        for(let i=0; i < this._categoriesCache.length; i++) {
            if(this._categoriesCache[i].id == productId) {
              cachedCategory = this._categoriesCache[i].category;
              break;
            }
        }
        return cachedCategory;
      }
    
    setBreadcrumbInfoInCache(productId:number, category:Category) : void {
        let categoryExists:boolean = false;
        for(let i=0; i < this._categoriesCache.length; i++) {
            if(this._categoriesCache[i].id == productId) {
              categoryExists = true;
                break;
            }
        }
    
        if(!categoryExists) {
          let cacheCategory:CacheCategory = new CacheCategory();
          cacheCategory.id = productId;
          cacheCategory.category = category;
          this._categoriesCache.push(cacheCategory);
        }
    }
}
