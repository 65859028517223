import { NgModule } from '@angular/core';
import { TermsConditionsComponent } from './terms-conditions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    TermsConditionsComponent
  ],
})
export class TermsConditionsModule { }