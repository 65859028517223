import { Customer } from './customer';
import { CheckoutProduct } from './checkoutProduct';

export class Checkout {
    id: number;
    reference: string;
    date: string;
    status: string;
    statusId: number;
    typeId: number;
    customer: Customer;
    products: Array<CheckoutProduct>;
    total: number;
    
    constructor() {
        this.products = [];
        this.customer = new Customer();
    }
}
