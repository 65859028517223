import { Component, Input, OnInit } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { Router } from '@angular/router';
import { BaseService } from '../../services/base.service';

@Component({
  selector: 'ngx-categories-grid',
  styleUrls: ['./categories-grid.component.scss'],
  templateUrl: './categories-grid.component.html',
})
export class CategoriesGridComponent extends BaseService {

  private img_avioes:string = this.baseUrl + "/assets/images/categories/avioes.jpg";
  private img_drones:string = this.baseUrl + "/assets/images/categories/drones.jpg";
  private img_eletronica:string = this.baseUrl + "/assets/images/categories/eletronica.png";
  private img_motores:string = this.baseUrl + "/assets/images/categories/motores.jpg";
  private img_carros:string = this.baseUrl + "/assets/images/categories/carros.png";
  private img_helices:string = this.baseUrl + "/assets/images/categories/helices.jpg";
  private img_acessorios:string = this.baseUrl + "/assets/images/categories/acessorios.png";
  private img_construcao:string = this.baseUrl + "/assets/images/categories/construcao.jpg";
  private img_barcos:string = this.baseUrl + "/assets/images/categories/barcos.jpg";

  constructor(private searchService: SearchService, private router: Router) {
    super();
  }

  goToProductsPage(categoryId: number) : void {
    this.searchService.setSearchPage(1);
    this.searchService.setSearch(1, categoryId.toString());
    this.router.navigate(['//pages/products']);
  }
}