import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import {AngularMaterialModule} from './ngmaterial.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { SwiperModule } from 'ngx-swiper-wrapper';

import {AboutUsModule} from './pages/about-us/about-us.module';
import {CheckoutModule} from './pages/checkout/checkout.module';
import {ContactModule} from './pages/contact/contact.module';
import {GalleryModule} from './pages/gallery/gallery.module';
import {BrandsModule} from './pages/brands/brands.module';
import {HomeModule} from './pages/home/home.module';
import {NewsModule} from './pages/news/news.module';
import {NewDetailModule} from './pages/new-detail/new-detail.module';
import {HowToBuyModule} from './pages/how-to-buy/how-to-buy.module';
import {TermsConditionsModule} from './pages/terms-conditions/terms-conditions.module';
import {HeaderComponent, FooterComponent} from './components';

import { StoreService } from './services/store.service';
import { PostService } from './services/post.service';
import { ProductService } from './services/product.service';
import { SharedModule } from './shared.module';
import { CheckoutService } from './services/checkout.service';
import { ManufacturerService } from './services/manufacturer.service';
import { ProductDetailModule } from './pages/product-detail/product-detail.module';
import { ProductsModule } from './pages/products/products.module';
import { SearchService } from './services/search.service';
import { SessionService } from './services/session.service';

import { EmbedVideoService } from 'ngx-embed-video';
import { DeviceDetectorService } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    HttpClientModule,
    HttpModule,
    SwiperModule,
    AboutUsModule,
    CheckoutModule,
    ContactModule,
    GalleryModule,
    BrandsModule,
    HomeModule,
    HowToBuyModule,
    NewsModule,
    NewDetailModule,
    ProductsModule,
    ProductDetailModule,
    TermsConditionsModule,
    SharedModule.forRoot()
  ],
  providers: [StoreService, PostService, SessionService, ProductService, CheckoutService, ManufacturerService, SearchService, EmbedVideoService, DeviceDetectorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
