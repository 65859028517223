import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Store } from '../../models';
import { StoreService } from '../../services/store.service';
import { SearchService } from '../../services/search.service';
import { BaseService } from '../../services/base.service';

@Component({
  selector: 'sadofly-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html'
})
export class FooterComponent extends BaseService {
  private _store: Store = new Store();
  private _mobile:boolean = false;
  private _logo:string = this.baseUrl + "/assets/images/sadofly.png";

  constructor(private storeService: StoreService, private searchService: SearchService, private router: Router, private route: ActivatedRoute) {
    super();
    this.storeService.getStoreInfo().then(res => this._store = res );
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() : void {
    this._mobile = (window.screen.width < 850);
  }

  goToProductsPage(categoryId: number) : void {
    this.searchService.setSearch(1, categoryId.toString());
    this.router.navigate(['//pages/products']);
  }
}