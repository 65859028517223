import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { AngularMaterialModule } from '../../ngmaterial.module';
import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [
    AngularMaterialModule,
    SharedModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
