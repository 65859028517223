import { Injectable, EventEmitter } from '@angular/core';
import { Checkout, CheckoutProduct, Customer } from '../models';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CheckoutService extends BaseService {

  private checkout: Checkout;
  private checkoutChanged: EventEmitter<CheckoutProduct[]> = new EventEmitter();
 
  constructor(private http: HttpClient) { 
    super();
    this.checkout = new Checkout();
  }

  getCheckoutChangedEmitter() {
    return this.checkoutChanged;
  }

  addItemToCheckout(product:CheckoutProduct): void {
    let newProduct: boolean = true;

    for(let i=0; i < this.checkout.products.length; i++) {
      if(this.checkout.products[i].id == product.id) {
        this.checkout.products[i].quantity += product.quantity;
        this.checkout.products[i].totalPrice = this.checkout.products[i].quantity * this.checkout.products[i].unitPrice;
        newProduct = false;
      }
    }

    if(newProduct) {
      product.totalPrice = product.quantity * product.unitPrice;
      this.checkout.products.push(product);
    }

    this.checkoutChanged.emit(this.checkout.products);
  }

  getTotalPrice(): number {
    this.checkout.total = 0;

    for(let i=0; i < this.checkout.products.length; i++) {
      this.checkout.total += this.checkout.products[i].totalPrice;
    }
    return this.checkout.total;
  }

  removeItemFromCheckout(productId:number): void {
    let indexToRemove:number;
    for(let i=0; i < this.checkout.products.length; i++) {
        if(this.checkout.products[i].id == productId)
            indexToRemove = i;
    }

    this.checkout.products.splice(indexToRemove, 1);
    this.checkoutChanged.emit(this.checkout.products);
  }

  clearCheckoutProducts(): void {
    this.checkout.products = [];
    this.checkoutChanged.emit(this.checkout.products);
  }

  updateProductQuantity(productId:number, quantity: number) : void {
    for(let i=0; i < this.checkout.products.length; i++) {
      if(this.checkout.products[i].id == productId) {

        if(this.checkout.products[i].quantity == 0 && quantity < 0) {
          this.checkout.products[i].quantity = 1;
          return;
        }
        
        this.checkout.products[i].quantity += quantity;
        this.checkout.products[i].totalPrice = this.checkout.products[i].quantity * this.checkout.products[i].unitPrice;
      }
    }

    this.checkoutChanged.emit(this.checkout.products);
  }

  getCheckoutItems(): Array<CheckoutProduct> {
    return this.checkout.products;
  }

  getCustomerInfo(): Customer {
    return this.checkout.customer;
  }

  updateCustomerInfo(customer: Customer): void {
    this.checkout.customer = customer;

    if(this.checkout.customer.cp7.length == 4) {
      this.checkout.customer.cP4 = this.checkout.customer.cp7;
    }
    else if(this.checkout.customer.cp7.length == 8) {
      this.checkout.customer.cP4 = this.checkout.customer.cp7.substring(0,4);
      this.checkout.customer.cP3 = this.checkout.customer.cp7.substring(5,8);
    }
  }

  getCheckoutForSubmit() : Checkout {
    return this.checkout;
  }

  submitCheckout(): any {
    return this.http.post(this.baseUrl + '/api/checkout/SubmitCheckout', this.getCheckoutForSubmit()); 
  }
}