export class CheckoutProduct {
    id: number;
    sku: string;
    name: string;
    quantity: number;
    unitPrice: number;
    promotion: number;
    totalPrice: number;
    img: string;
}
