import { Spec } from './spec';

export class Product {
    id: number;
    sku: string;
    name: string;
    manufacturerName: string;
    status: string;
    price: number;
    promotion: number;
    // categories: Array<number>;
    images: Array<string>;
    cards: Array<Spec>;
    specs: Array<Spec>;
    urls: Array<Spec>;
    videos: Array<Spec>;
    shortDescription: string;
    published: boolean;
    active: boolean;

    constructor() {
        this.images = new Array<string>();
        this.cards = new Array<Spec>();
        this.specs = new Array<Spec>();
        this.urls = new Array<Spec>();
        this.videos = new Array<Spec>();
    }
}
