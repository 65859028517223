import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Post, Session, Navigation } from '../../models';
import { PostService } from '../../services/post.service';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'ngx-news',
  styleUrls: ['./news.component.scss'],
  templateUrl: './news.component.html',
})
export class NewsComponent implements OnDestroy {
  private _news:Array<Post> = [];
  private sessionSubscription: Subscription = null;
  private navigationSubscription: Subscription = null;
  private postSubscription: Subscription = null;

  constructor(private router: Router, private route: ActivatedRoute, private postService: PostService, private sessionService: SessionService) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.getNews();
    this.updateSessionInfo();
  }

  getNews(): any {
    this._news = this.postService.getNewsFromCache();

    if(this._news.length == 0) {
      this.postSubscription = this.postService.getNews().subscribe(res => {
        this._news = res.object;
        this.postService.setNewsToCache(this._news);
      });
    }
  }

  goToPost(postId:number): void {
    this.router.navigate(['//pages/new-detail', postId]);
  }

  updateSessionInfo() : void {
    let session:Session = this.sessionService.getSessionInfo();

    if(session == null) {
      this.sessionSubscription = this.sessionService.createSessionObject().subscribe(data => {
         this.sessionService.setSessionIdentifier(data.object);
         this.updateNavigation();
      });
    }
    else {
      this.updateNavigation();
    }
  }

  updateNavigation() : void {
    let navigation:Navigation = new Navigation();
    navigation.page = "news";
    this.navigationSubscription = this.sessionService.addNavigationStep(navigation).subscribe(data => { });
  }

  ngOnDestroy()
  {
    if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
    if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
    if(this.postSubscription != null) this.postSubscription.unsubscribe();
  }
}
