import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Manufacturer, Session, Navigation } from '../../models';
import { ManufacturerService } from '../../services/manufacturer.service';
import { SearchService } from '../../services/search.service';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-brands',
  styleUrls: ['./brands.component.scss'],
  templateUrl: './brands.component.html',
})
export class BrandsComponent implements OnDestroy {
  
  private _manufacturers:Manufacturer[] = [];

  private sessionSubscription: Subscription = null;
  private navigationSubscription: Subscription = null;
  private manufacturersSubscription: Subscription = null;

  constructor(private router: Router, private manufacturerService: ManufacturerService, private searchService: SearchService, private sessionService: SessionService) {

  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.updateSessionInfo();
    this.getBrands();
  }

  getBrands() : void {
    this._manufacturers = this.manufacturerService.getManufacturersCache();

    if(this._manufacturers.length == 0) {
      this.manufacturersSubscription = this.manufacturerService.getAllManufacturers().subscribe((data: any) => {
        this._manufacturers = data.object; 
        this.manufacturerService.setManufacturersCache(this._manufacturers);
      });
    }
  }

  goToProductsPageByManufacturer(manufacturerId: number) : void {
    this.searchService.setSearchPage(1);
    this.searchService.setSearch(2, manufacturerId.toString());
    this.router.navigate(['//pages/products']);
  }

  updateSessionInfo() : void {
    let session:Session = this.sessionService.getSessionInfo();

    if(session == null) {
      this.sessionSubscription = this.sessionService.createSessionObject().subscribe(data => {
         this.sessionService.setSessionIdentifier(data.object);
         this.updateNavigation();
      });
    }
    else {
      this.updateNavigation();
    }
  }

  updateNavigation() : void {
    let navigation:Navigation = new Navigation();
    navigation.page = "brands";
    this.navigationSubscription = this.sessionService.addNavigationStep(navigation).subscribe(data => { });
  }

  ngOnDestroy()
  {
    if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
    if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
    if(this.manufacturersSubscription != null) this.manufacturersSubscription.unsubscribe();
  }
}


