import { Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CheckoutProduct, Customer, HttpResponse, Store, Product, Navigation, Session } from '../../models';
import { isNumber } from 'util';
import { post } from 'selenium-webdriver/http';
import { CheckoutService } from '../../services/checkout.service';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'store-checkout',
  styleUrls: ['./checkout.component.scss'],
  templateUrl: './checkout.component.html',
})
export class CheckoutComponent implements OnDestroy {

    private _products: Array<CheckoutProduct> = [];
    private _customer: Customer;
    private _step: number = 1;
    private _totalPrice: number = 0;
    private _checkoutRef:string = "";
    private _productImageFitsInWidth:boolean = true;
    private _width375400:boolean = false;
    private _widthMoreThan400:boolean = false;
    private _widthLessThan375:boolean = false;
    private _gdpr:boolean = false;

    private validName: boolean = true;
    private validEmail: boolean = true;
    private validAddress: boolean = true;
    private validPostalCode: boolean = true;
    private validCity: boolean = true;
    private validGdpr: boolean = true;

    private sessionSubscription: Subscription = null;
    private navigationSubscription: Subscription = null;
    private checkoutSubscription: Subscription = null;

    constructor(private router: Router, private http: HttpClient, private checkoutService:CheckoutService, private sessionService: SessionService) {
      this.checkScreenSize();
    }

    ngOnInit() {
      window.scrollTo(0,0);
      this.getCheckoutItems();
      this.updateSessionInfo();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.checkScreenSize();
    }
  
    checkScreenSize() : void {
      this._productImageFitsInWidth = (window.screen.width >= 750);
      this._width375400 = (window.screen.width >= 375 && window.screen.width < 400);
      this._widthMoreThan400 = (window.screen.width >= 400);
      this._widthLessThan375 = (window.screen.width < 375);
    }

    goToProduct(productId:number): void {
      this.router.navigate(['//pages/product-detail', productId]);
    }

    getCheckoutItems() {
      this._products = this.checkoutService.getCheckoutItems();
      this._totalPrice = this.checkoutService.getTotalPrice();
    }

    goToCheckoutStep() {
      this._step = 1;
      window.scrollTo(0,0);
    }

    goToCustomerStep() {
      this._customer = this.checkoutService.getCustomerInfo();
      this._step = 2;
      window.scrollTo(0,0);
    }

    validateContactAndSubmit() {
      if(this.validateContact()) {
        this.checkoutService.updateCustomerInfo(this._customer);
        this.saveCheckout();
        this._step = 3;
      } 
    }

    // Business functions

    incrementProductQtd(productId: number) : void {
      this.checkoutService.updateProductQuantity(productId, 1);
      this.getCheckoutItems();
    }

    decrementProductQtd(productId: number) : void {
      let product: CheckoutProduct = this.getProduct(productId);

      if(product != null) {
        if(product.quantity != 1) {
          this.checkoutService.updateProductQuantity(productId, -1);
          this.getCheckoutItems();
        }
      }
    }

    removeProduct(productId: number) : void {
      this.checkoutService.removeItemFromCheckout(productId);
      this.getCheckoutItems();
    }

    getProduct(productId:number): CheckoutProduct {
      for (let i = 0; i < this._products.length; i++) {
        if(this._products[i].id == productId) return this._products[i];
      }
      return null;
    }

    validateContact(): boolean {
      let regexp:RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                                     
      this.validName = (this._customer.name != null && this._customer.name != "");
      this.validEmail = (this._customer.email != null && this._customer.email != "" && regexp.test(this._customer.email));
      this.validAddress = (this._customer.address != null && this._customer.address != "");
      this.validCity = (this._customer.city != null && this._customer.city != "");
      this.validPostalCode = this.validatePostalCode(this._customer.cp7);
      this.validGdpr = this._gdpr;

      return this.validGdpr && this.validName && this.validEmail && this.validAddress && this.validPostalCode && this.validCity;
    }

    validatePostalCode(postalCode:string): boolean {
      let result: boolean = false;

      if(postalCode != null) {
        if(postalCode.length == 4) {
          if(!isNaN(Number(postalCode))) result = true;
        }
        else if(postalCode.length == 8) {
          if(!isNaN(Number(postalCode.replace("-","")))) result = true;
        }
      }

      return result;
    }

    isNumberKey(event): boolean {
      const charCode: number = (event.which) ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;
    }

    saveCheckout() {
      this.checkoutSubscription = this.checkoutService.submitCheckout().subscribe(res => { 
        window.scrollTo(0,0);
        this._checkoutRef = res.object.reference;
        this._step = 3;
        this.checkoutService.clearCheckoutProducts();
      });
    }

    updateSessionInfo() : void {
      let session:Session = this.sessionService.getSessionInfo();
  
      if(session == null) {
        this.sessionSubscription = this.sessionService.createSessionObject().subscribe(data => {
           this.sessionService.setSessionIdentifier(data.object);
           this.updateNavigation();
        });
      }
      else {
        this.updateNavigation();
      }
    }
  
    updateNavigation() : void {
      let navigation:Navigation = new Navigation();
      navigation.page = "checkout";
      this.navigationSubscription = this.sessionService.addNavigationStep(navigation).subscribe(data => { });
    }

    ngOnDestroy()
    {
      if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
      if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
      if(this.checkoutSubscription != null) this.checkoutSubscription.unsubscribe();
    }
}
