import { Product } from './product';
import { Category } from './category';

export class SearchOutput {
    title: string;
    products: Array<Product>;
    childCategories: Array<Category>;
    total: number;
    pageCount: number;

    constructor() {
        this.products = new Array<Product>();
        this.childCategories = new Array<Category>();
    }
}
