export class Category {
    id: number;
    name: string;
    logo: string;
    childs: Category[];
    parent: Category;
    selected: boolean;

    constructor() {
        this.selected = false;
    }
}
