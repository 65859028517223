import { Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SearchInput, SearchOutput, Category, TreeNode, Manufacturer, Session, Navigation } from '../../models';
import { SearchService } from '../../services/search.service';
import { ProductService } from '../../services/product.service';
import { ManufacturerService } from '../../services/manufacturer.service';
import { SessionService } from '../../services/session.service';
import {  Subscription } from 'rxjs';

@Component({
  selector: 'ngx-products',
  styleUrls: ['./products.component.scss'],
  templateUrl: './products.component.html',
})
export class ProductsComponent implements OnDestroy {

  private _showBreadcrumb: boolean = false;
  private _searchInput: SearchInput;
  private _searchOutput: SearchOutput;
  private _selectedPage: number = 1;
  private _pages: Array<number> = [];
  private _totalPages: number = 0;
  private _showEmptyMessage: boolean = false;
  private _selectedCategoryId: number = 0;
  private _category: Category;
  private _breadcrumb: TreeNode[] = [];
  private _categoriesTree: Category[];
  private _filteredCategoriesTree : Category[] = [];
  private _parentCategory: Category;
  private _productsPerPage: number = 12;
  //private _searchCategory:number = 5;
  private expanded:boolean = true;
  private haspopup:boolean = true;
  private _extendedCards:boolean = false;
  private _sortAsc: boolean = true;
  private _rows: number = 12;
  private _sortField: number = 1; // por defeito, a ordenação é por nome
  private _mobile:boolean = false;
  private _pc:boolean = true;
  private _largeFilterBarFitsInWidth:boolean = true;
  private _smallFilterBarFitsInWidth:boolean = true;
  private _filtersFitsInWidth:boolean = true;
  private _categoriesFitsInWidth:boolean = true;
  private _manufacturers:Manufacturer[] = [];
  private _categoriesExpanded: boolean = false;

  private searchSubscription: Subscription = null;
  private sessionSubscription: Subscription = null;
  private navigationSubscription: Subscription = null;
  private manufacturerSubscription: Subscription = null;
  private recentProductsSubscription: Subscription = null;
  private categorySubscription: Subscription = null;
  private categoriesTreeSubscription: Subscription = null;
  private searchEmitSubscription: Subscription = null;
  
  constructor(private router: Router, private route: ActivatedRoute, 
              private searchService: SearchService, private productService: ProductService,
              private manufacturerService: ManufacturerService,
              private sessionService: SessionService) {
                this.checkScreenSize();

    this.searchEmitSubscription = this.searchService.getSearchChangedEmitter()
    .subscribe(item => { 
      this.searchProducts();
      this.getCategoriesTree();
      this.getManufacturers();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() : void {
    this._largeFilterBarFitsInWidth = (window.screen.width >= 1000);
    this._smallFilterBarFitsInWidth = (window.screen.width >= 660 && window.screen.width < 1000);
    this._categoriesFitsInWidth = (window.screen.width >= 1300);
    this._mobile = (window.screen.width < 420);
    this._pc = (window.screen.width >= 830);
  }

  ngOnInit() {
    this._searchOutput = new SearchOutput();
    this._manufacturers = this.manufacturerService.getManufacturersCache();
    this.getCategoriesTree();
    this.searchProducts();
    this.getManufacturers();
  }

  searchProductsByCategory(categoryId:number): void {
    this._selectedCategoryId = categoryId;
    this._selectedPage = 1;
    this.searchService.setSearch(1, categoryId.toString());
  }

  searchProductsByManufacturer(manufacturerId:number): void {
    this._selectedPage = 1;
    this.searchService.setSearch(2, manufacturerId.toString());
  }

  searchProducts(){
    this._searchInput = this.searchService.getSearch();
    this.updateSessionInfo();

    if(this._searchInput.type == null) this.router.navigate(['//pages/home']);

    if(this._searchInput.type == 1) {
      this._selectedCategoryId = Number(this._searchInput.value);
    }
    else {
      this._selectedCategoryId = 0;
    }

    // Search by recent products
    if(this._searchInput.type == 3) {
      this._showBreadcrumb = false;
      this._parentCategory = null;
      this.getRecentProducts();
    }
    else {

      // only for category search
      this._showBreadcrumb = (this._searchInput.type == 1);

      let searchOutput: SearchOutput = this.searchService.getSearchFromCache(this._searchInput);

      if(searchOutput == null) {
        this.searchSubscription = this.searchService.performSearch(this._searchInput)
        .subscribe(res => { 
          if(res.object == null) {
            this._searchOutput = new SearchOutput();
          }
          else {
            this._searchOutput = res.object;
          }
          
          this.searchService.setSearchInCache(this._searchInput, this._searchOutput);

          if(this._searchOutput == null) {
            this._showEmptyMessage = true;
          }
          else {
            this._showEmptyMessage = (this._searchOutput.products.length == 0);
          }

          if(!this._showEmptyMessage && this._showBreadcrumb) {
            this.updateBreadcrumb();
          }

          this.setPaginationOptions();
          window.scrollTo(0,0);
        });
      }
      else {
        this._searchOutput = searchOutput;
      }
    }
  }

  getManufacturers(): void {
    if(this._searchInput.type == 2) {
      this._manufacturers = this.manufacturerService.getManufacturersCache();

      if(this._manufacturers.length == 0) {
        this.manufacturerSubscription = this.manufacturerService.getAllManufacturers()
        .subscribe((data: any) => {
          this._manufacturers = data.object; 
          this.manufacturerService.setManufacturersCache(this._manufacturers);
          this.selectManufacturer();
        });
      }
      else {
        this.selectManufacturer();
      } 
    }
  }

  selectManufacturer(): void {
    for(let i:number = 0; i < this._manufacturers.length; i++) {  
      if(this._manufacturers[i].id == Number(this._searchInput.value)) {
        this._manufacturers[i].selected = true;
      }
      else {
        this._manufacturers[i].selected = false;
      }
    }
  }

  getRecentProducts(): void {
    this.recentProductsSubscription = this.productService.getRecentProducts(this._searchInput)
    .subscribe((data: any) => {
      this._searchOutput = data.object;
      this._showEmptyMessage = (this._searchOutput.products.length == 0);
      this.setPaginationOptions();
      window.scrollTo(0,0);
    });
  }

  updateBreadcrumb(): void {
    if(this._selectedCategoryId != 0) {
      this._category = this.searchService.getCategoryInfoFromCache(this._selectedCategoryId);

      if(this._category == null) {
        this.categorySubscription = this.searchService.getCategoryInfo(this._selectedCategoryId)
        .subscribe(res => { 
          this._category = res.object;
          this.searchService.setCategoryInCache(this._category); 
          this.fillBreadcrumbLayout(); 
        });
      }
    }
  }

  fillBreadcrumbLayout() : void {
    this._breadcrumb = [];
    let treeNode: TreeNode = new TreeNode();
    treeNode.id = this._category.id;
    treeNode.name = this._category.name;
    this._breadcrumb.push(treeNode);

    if(this._category.parent != null) this.completeBreadcrumbParentInfo(this._category.parent);
    this._breadcrumb = this._breadcrumb.reverse();
  }

  completeBreadcrumbParentInfo(category:Category): void {
    let treeNode: TreeNode = new TreeNode();
    treeNode.id = category.id;
    treeNode.name = category.name;
    this._breadcrumb.push(treeNode);
    if(category.parent != null) this.completeBreadcrumbParentInfo(category.parent);
  }

  updateCategoriesTree(): void {
    if(this._selectedCategoryId != 0) {
      let levelCategories: Category[] = [];

      for(let i:number = 0; i < this._categoriesTree.length; i++) {
        if(this._categoriesTree[i].id == this._selectedCategoryId) {
            if(this._categoriesTree[i].parent != null) {
              this._parentCategory = this.getCategoryById(this._categoriesTree[i].parent.id);
              levelCategories = this.getChildCategories(this._categoriesTree[i].parent.id);

              for(let j:number = 0; j < levelCategories.length; j++) {
                if(levelCategories[j].id == this._selectedCategoryId) {
                  levelCategories[j].selected = true;
                  levelCategories[j].childs = this.getChildCategories(levelCategories[j].id);
                }
                else {
                  levelCategories[j].childs = [];
                  levelCategories[j].selected = false;
                }
              }
            }
            else {
              this._parentCategory = null;
              levelCategories = this.getHighLevelCategories();

              for(let j:number = 0; j < levelCategories.length; j++) {
                if(levelCategories[j].id == this._selectedCategoryId) {
                  levelCategories[j].selected = true;
                  levelCategories[j].childs = this.getChildCategories(levelCategories[j].id);
                }
                else {
                  levelCategories[j].childs = [];
                  levelCategories[j].selected = false;
                }
              }
            }
            break;
        }
      }

      this._filteredCategoriesTree = levelCategories;
    }
    else {
      let categories: Category[] = this.getHighLevelCategories();
      for (let i = 0; i < categories.length; i++) {
        categories[i].childs = [];
        categories[i].selected = false;        
      }
      this._filteredCategoriesTree =  categories;
    }
  }

  getHighLevelCategories() : Category[] {
    let categories: Category[] = [];
    for(let i:number = 0; i < this._categoriesTree.length; i++) {
      if(this._categoriesTree[i].parent == null) {
        categories.push(this._categoriesTree[i]);
      }
    }

    return categories;
  }

  getChildCategories(categoryId:number) : Category[] {
    let categories: Category[] = [];
    for(let i:number = 0; i < this._categoriesTree.length; i++) {
      if(this._categoriesTree[i].parent != null) {
        if(this._categoriesTree[i].parent.id == categoryId) {
          categories.push(this._categoriesTree[i]);
        }
      }
    }

    return categories;
  }

  getCategoryById(categoryId:number) : Category {
    let category: Category = null;
    for(let i:number = 0; i < this._categoriesTree.length; i++) {
      if(this._categoriesTree[i].id == categoryId) {
        category = this._categoriesTree[i];
      }
    }
    return category;
  }

  setPaginationOptions(): void {
    this._pages = [];
    this._totalPages = this._searchOutput.pageCount;

    if(this._searchOutput.pageCount <= 5) {
      for(let i:number = 0; i < this._searchOutput.pageCount; i++) {
        this._pages.push(i+1);
      }
    }
    else {
      if(this._selectedPage < 3) {
        for(let i:number = 0; i < 5; i++) {
          this._pages.push(i+1);
        }
      }
      else if(this._selectedPage > (this._searchOutput.pageCount - 3)) {
        for(let i:number = (this._searchOutput.pageCount-5); i < this._searchOutput.pageCount; i++) {
          this._pages.push(i+1);
        }
      }
      else {
        for(let i:number = (this._selectedPage-2); i <= (this._selectedPage+2); i++) {
          this._pages.push(i+1);
        }
      }
    }
  }

  goToPage(page:number) {
    this._selectedPage = page;
    this.searchService.setSearchPage(page);
  }

  goToPreviousPage(): void {
    this.goToPage(this._selectedPage - 1);
  }

  goToNextPage(): void {
    this.goToPage(this._selectedPage + 1);
  }

  getCategoriesTree() : void {
    let tree: Category[] = this.productService.getCategoriesTreeFromCache();

    if(tree == null) {
      this.categoriesTreeSubscription = this.productService.getCategoriesTree()
      .subscribe(res => { 
        this._categoriesTree = res.object;
        this.productService.setCategoriesTreeInCache(res.object); 
        this.updateCategoriesTree();
      });
    }
    else {
      this._categoriesTree = tree;
      this.updateCategoriesTree();
    }
  }

  activateGridView() : void {
    this._extendedCards = false;
  }

  activateExtendedGridView() : void {
    this._extendedCards = true;
  }

  activateAscendentOrder() : void {
    this.searchService.setSearchOrder(true);
    this._sortAsc = true;
  }

  activateDescendentOrder() : void {
    this.searchService.setSearchOrder(false);
    this._sortAsc = false;
  }

  updateSortField() : void {
    this.searchService.setSearchOrderField(this._sortField);
  }

  updateReturnedRows() : void {
    this.searchService.setSearchReturnedRows(this._rows);
  }

  updateSessionInfo() : void {
    let session:Session = this.sessionService.getSessionInfo();

    if(session == null) {
      this.sessionSubscription = this.sessionService.createSessionObject()
      .subscribe(data => {
         this.sessionService.setSessionIdentifier(data.object);
         this.updateNavigation();
      });
    }
    else {
      this.updateNavigation();
    }
  }

  updateNavigation() : void {
    let navigation:Navigation = new Navigation();
    navigation.page = "products";
    navigation.searchType = this._searchInput.type;
    navigation.searchValue = this._searchInput.value;
    this.navigationSubscription = this.sessionService.addNavigationStep(navigation)
    .subscribe(data => { });
  }

  updateCategoryExpand() {
    this._categoriesExpanded = !this._categoriesExpanded;
  }

  ngOnDestroy()
  {
      if(this.searchSubscription != null) this.searchSubscription.unsubscribe();
      if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
      if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
      if(this.manufacturerSubscription != null) this.manufacturerSubscription.unsubscribe();
      if(this.recentProductsSubscription != null) this.recentProductsSubscription.unsubscribe();
      if(this.categorySubscription != null) this.categorySubscription.unsubscribe();
      if(this.categoriesTreeSubscription != null) this.categoriesTreeSubscription.unsubscribe();
      if(this.searchEmitSubscription != null) this.searchEmitSubscription.unsubscribe();
  }
}
