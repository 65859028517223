import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpResponse, Post } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class PostService extends BaseService {

  private _news: Post[] = [];
  private _banners: Post[] = [];
 
  constructor(private http: HttpClient) { 
    super();
  }

  getNews(): any {
      return this.http.get<HttpResponse>(this.baseUrl + '/api/store/GetNewsEventsPosts');
  }

  setNewsToCache(news:Post[]) : void {
      this._news = news;
  }

  getNewsFromCache(): Post[] {
      return this._news;
  }

  getNewById(postId:number) {
     let post:Post = null;

     if(this._news != null) {
        for(let i=0; i < this._news.length; i++) {
            if(this._news[i].id == postId) return this._news[i];
        }   
     }

     return post;    
  }

  getBanners(): any {
    return this.http.get<HttpResponse>(this.baseUrl + '/api/store/GetBannerPosts');
  }

  setBannersToCache(banners:Post[]) : void {
    this._banners = banners;
  }

  getBannersFromCache(): Post[] {
    return this._banners;
  }
}
