import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { Category } from '../../models';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'sadofly-bottom-header',
  styleUrls: ['./bottom-header.component.scss'],
  templateUrl: './bottom-header.component.html'
})
export class BottomHeaderComponent {
  private expanded:boolean = true;
  private haspopup:boolean = true;
  private _mobile: boolean = false;
  private _searchText: string = "";

  private _categories: Category[] = [];

  constructor(private router: Router, private productService: ProductService, private searchService: SearchService) {
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() : void {
    this._mobile = (window.screen.width < 813);
  }

  ngOnInit() {
    this.productService.getMenuCategories().subscribe((data: any) => {
      this._categories = data.object;
      
      //this.productService.setRecentProductsCache(this._newProducts);
    });
  }

  searchText() : void {
    if(this._searchText.length > 3) {
      this.searchService.setSearchPage(1);
      this.searchService.setSearch(0, this._searchText);
      this.router.navigate(['//pages/products']);
    }
  }

  submitSearch(event) : void {
    if(event.keyCode == 13) this.searchText();
 } 

  goToProductsPage(categoryId: number) : void {
    this.searchService.setSearchPage(1);
    this.searchService.setSearch(1, categoryId.toString());
    this.router.navigate(['//pages/products']);
  }
}