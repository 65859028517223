import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PostService } from '../../services/post.service';
import { Post, Session, Navigation } from '../../models';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-new-detail',
  styleUrls: ['./new-detail.component.scss'],
  templateUrl: './new-detail.component.html',
})
export class NewDetailComponent implements OnDestroy {

  private _post: Post = new Post(); 
  private sessionSubscription: Subscription = null;
  private navigationSubscription: Subscription = null;
  private postSubscription: Subscription = null;

  constructor(private router: Router, private route: ActivatedRoute, private postService: PostService, private sessionService: SessionService) {
  }

  ngOnInit() {
    if (this.route.snapshot.params.id !== undefined) {
      let post:Post = this.postService.getNewById(this.route.snapshot.params.id);

      if(post == null) {
        this.postSubscription = this.postService.getNews().subscribe(res => {
          this.postService.setNewsToCache(res.object);
          this._post = this.postService.getNewById(this.route.snapshot.params.id);
          this.updateSessionInfo(this._post.id);
        });
      }
      else {
        this._post = post;
        this.updateSessionInfo(this._post.id);
      }
    }
  }

  goToNews(): void {
    this.router.navigate(['//pages/news']);
  }

  updateSessionInfo(newId: number) : void {
    let session:Session = this.sessionService.getSessionInfo();

    if(session == null) {
      this.sessionSubscription = this.sessionService.createSessionObject().subscribe(data => {
         this.sessionService.setSessionIdentifier(data.object);
         this.updateNavigation(newId);
      });
    }
    else {
      this.updateNavigation(newId);
    }
  }

  updateNavigation(newId: number) : void {
    let navigation:Navigation = new Navigation();
    navigation.page = "new-detail";
    navigation.productId = newId;
    this.navigationSubscription = this.sessionService.addNavigationStep(navigation).subscribe(data => { });
  }

  ngOnDestroy()
  {
    if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
    if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
    if(this.postSubscription != null) this.postSubscription.unsubscribe();
  }
}
