import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product, CheckoutProduct } from '../../models';
import { CheckoutService } from '../../services/checkout.service';

@Component({
  selector: 'ngx-extended-product-card',
  styleUrls: ['./extended-product-card.component.scss'],
  templateUrl: './extended-product-card.component.html',
})
export class ExtendedProductCardComponent {
  @Input('product') product: Product;

  private _quantity: number = 1;

  constructor(private router: Router, private checkoutService:CheckoutService) {
  }

  goToProduct(productId:number): void {
    this.router.navigate(['//pages/product-detail', productId]);
  }

  incrementQuantity(): void {
    this._quantity++;
  }

  decrementQuantity():void {
    if(this._quantity > 1) {
      this._quantity--;
    }
  }

  addToCheckout(): void {
    let checkoutProduct:CheckoutProduct = new CheckoutProduct();
    checkoutProduct.id = this.product.id;
    checkoutProduct.sku = this.product.sku;
    checkoutProduct.name = this.product.name;
    checkoutProduct.quantity = this._quantity;
    checkoutProduct.unitPrice = this.product.price;
    checkoutProduct.promotion = this.product.promotion;
    checkoutProduct.img = this.product.images[0];
    this.checkoutService.addItemToCheckout(checkoutProduct);
  }
}