import { Component, Input, ViewChild } from '@angular/core';
import { Product } from '../../models';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperNavigationInterface, SwiperPaginationInterface, SwiperKeyboardInterface, SwiperScrollbarInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'ngx-product-swiper',
  templateUrl: './product-swiper.component.html',
})
export class ProductSwiperComponent {
    public index: number = 0;

    public scrollConfig: SwiperScrollbarInterface = {
      draggable: true,
      hide: false,
      snapOnRelease: false
    };
  
    public keyboardConfig: SwiperKeyboardInterface = {
      enabled: true
    };
  
    public paginationConfig: SwiperPaginationInterface = {
      clickable: true,
      currentClass: '.swiper-pagination',
    }
  
    public navigationConfig: SwiperNavigationInterface = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
    
    public config: SwiperConfigInterface = {
      direction: 'horizontal',
      slidesPerView: 4,
      centeredSlides: false,
      keyboard: this.keyboardConfig,
      scrollbar : this.scrollConfig,
      mousewheel: false,
      pagination: this.paginationConfig,
      navigation: this.navigationConfig,
      spaceBetween: 20,
      loop: false
    };
    
  @Input('products') products: Product[];
  @Input('slidesPerView') slidesPerView: number;
  @ViewChild(SwiperComponent) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
        this.config.slidesPerView = this.slidesPerView;
        this.componentRef.directiveRef.update();
      }, 1000);
  }
}