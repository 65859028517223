import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Promise} from 'es6-promise';
import { HttpResponse,Store, Contact } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class StoreService extends BaseService {

  private _store:Store;

  constructor(private http: HttpClient) { 
    super();
  }

  getStoreInfo(): any {
    if(this._store == null) {
        let promise:any = new Promise((resolve, reject) => {
            this.http.get<HttpResponse>(this.baseUrl + '/api/store/get')
            .toPromise()
            .then(
                res => { 
                this._store = res.object;
                resolve(this._store);
                }
            );
        });
    
        return promise;
    }
    else {
        return new Promise((resolve, reject) => resolve(this._store));
    }
  }

  submitContact(contact: Contact): any {
    return this.http.post(this.baseUrl + '/api/store/SubmitContact', contact);
  }

}