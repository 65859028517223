/* tslint:disable:member-ordering */
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {

  constructor(private el: ElementRef) { }

  @Input() defaultColor: string;

  @Input('appHighlight') highlightColor: string;

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.highlightColor || this.defaultColor || 'red', 'black');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null, null);
  }

  private highlight(backgroundcolor: string, color: string) {
    this.el.nativeElement.style.backgroundColor = backgroundcolor;

    if(color != null) {
        this.el.nativeElement.style.color = color;
    }
    else {
        this.el.nativeElement.style.color = 'white';
    }
    
  }
}