import { NgModule } from '@angular/core';
import { CheckoutComponent } from './checkout.component';
import { AngularMaterialModule } from '../../ngmaterial.module';
import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [
    AngularMaterialModule,
    SharedModule
  ],
  declarations: [CheckoutComponent]
})
export class CheckoutModule { }
