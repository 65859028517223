import { Injectable, EventEmitter } from '@angular/core';
import {Promise} from 'es6-promise';
import { HttpClient } from '@angular/common/http';
import { HttpResponse, SearchInput, SearchOutput, CacheSearch, CacheCategory, Category } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class SearchService extends BaseService {

  private searchObject:SearchInput;
  private _resultsCache: CacheSearch[] = new Array<CacheSearch>();
  private _categoriesCache: CacheCategory[] = new Array<CacheCategory>();

  private searchChanged: EventEmitter<number> = new EventEmitter();
 
  constructor(private http: HttpClient) { 
    super();

    this.searchObject = new SearchInput();
    this.searchObject.page = 1;
    this.searchObject.rows = 12;
    this.searchObject.sortField = 1; // default field is name
    this.searchObject.sortAsc = true;
    this.searchObject.type = null;
  }
 
  setSearch(type:number, value:string): void {
    this.searchObject.page = 1;
    this.searchObject.type = type;
    this.searchObject.value = value;

    // Raise event if we search is by text
    //if(type == 0) 
    this.searchChanged.emit(1);
  }

  setSearchPage(page:number): void {
    this.searchObject.page = page;
    this.searchChanged.emit(1);
  }

  setSearchReturnedRows(rows: number): void {
    this.searchObject.rows = rows;
    this.searchChanged.emit(1);
  }

  setSearchOrderField(sortField: number): void {
    this.searchObject.sortField = sortField;
    this.searchChanged.emit(1);
  }

  setSearchOrder(sortAsc: boolean): void {
    this.searchObject.sortAsc = sortAsc;
    this.searchChanged.emit(1);
  }

  getSearch(): SearchInput {
    return this.searchObject;
  }

  getSearchChangedEmitter() {
    return this.searchChanged;
  }

  performSearch(searchInput: SearchInput): any {
    return this.http.post(this.baseUrl + '/api/search/SearchProducts', searchInput);
  }

  getSearchFromCache(searchInput:SearchInput) : SearchOutput {
    let cachedResults : SearchOutput = null;
    for(let i=0; i < this._resultsCache.length; i++) {
        if(this._resultsCache[i].input.type == searchInput.type &&
           this._resultsCache[i].input.page == searchInput.page &&
           this._resultsCache[i].input.value == searchInput.value &&
           this._resultsCache[i].input.rows == searchInput.rows &&
           this._resultsCache[i].input.sortField == searchInput.sortField &&
           this._resultsCache[i].input.sortAsc == searchInput.sortAsc) {
            cachedResults = this._resultsCache[i].output;
            break;
        }
    }
    return cachedResults;
  }

  setSearchInCache(searchInput:SearchInput, searchOutput:SearchOutput) : void {
    let searchExists:boolean = false;
    for(let i=0; i < this._categoriesCache.length; i++) {
      if(this._resultsCache[i].input.type == searchInput.type &&
        this._resultsCache[i].input.page == searchInput.page &&
        this._resultsCache[i].input.value == searchInput.value) {
          searchExists = true;
            break;
        }
    }

    if(!searchExists) {
      let result: CacheSearch = new CacheSearch();
      result.input = new SearchInput();
      result.input.page = searchInput.page;
      result.input.type = searchInput.type;
      result.input.value = searchInput.value;
      result.output = searchOutput;
      this._resultsCache.push(result);
    }
  }

  getCategoryInfo(categoryId:number): any {
    return this.http.get<HttpResponse>(this.baseUrl + '/api/category/GetUpstreamCategories/' + categoryId);
  }

  getCategoryInfoFromCache(categoryId:number) : Category {
    let cachedCategory : Category = null;
    for(let i=0; i < this._categoriesCache.length; i++) {
        if(this._categoriesCache[i].id == categoryId) {
          cachedCategory = this._categoriesCache[i].category;
          break;
        }
    }
    return cachedCategory;
  }

  setCategoryInCache(category:Category) : void {
    let categoryExists:boolean = false;
    for(let i=0; i < this._categoriesCache.length; i++) {
        if(this._categoriesCache[i].id == category.id) {
          categoryExists = true;
            break;
        }
    }

    if(!categoryExists) {
      let cacheCategory:CacheCategory = new CacheCategory();
      cacheCategory.id = category.id;
      cacheCategory.category = category;
      this._categoriesCache.push(cacheCategory);
    }
  }
}