import { Component, HostListener } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { Router } from '@angular/router';
import { CheckoutService } from '../../services/checkout.service';
import { CheckoutProduct } from '../../models';
import { BaseService } from '../../services/base.service';

@Component({
  selector: 'sadofly-top-header',
  styleUrls: ['./top-header.component.scss'],
  templateUrl: './top-header.component.html'
})
export class TopHeaderComponent extends BaseService {
  private expanded:boolean = true;
  private haspopup:boolean = true;
  private _checkoutProducts: CheckoutProduct[] = [];
  private _searchText: string = "";
  private _orderTotal: number = 0;
  private _numItems: number = 0;
  private _mobile: boolean = false;
  private _newProductsFitInWidth:boolean = false;
  private _logo:string = this.baseUrl + "/assets/images/sadofly.png";

  constructor(private router: Router, private searchService: SearchService, private checkoutService: CheckoutService) {
    super();
    this.checkScreenSize();
    
    this.checkoutService.getCheckoutChangedEmitter()
    .subscribe(items => { 

      let numItems: number = 0;

      for (let i = 0; i < items.length; i++) {
         numItems += items[i].quantity;
      }

      this._numItems = numItems;
      this._checkoutProducts = items;
      this._orderTotal = this.calculateOrderTotal();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() : void {
    this._mobile = (window.screen.width < 813);
    this._newProductsFitInWidth = (window.screen.width >= 1100);
  }

  goToProductsPage(categoryId: number) : void {
    this.searchService.setSearch(1, categoryId.toString());
    this.router.navigate(['//pages/products']);
  }

  goToNewProductsPage(): void {
    this.searchService.setSearch(3, "");
    this.router.navigate(['//pages/products']);
  }

  searchText() : void {
    if(this._searchText.length > 3) {
      this.searchService.setSearch(0, this._searchText);
      this.router.navigate(['//pages/products']);
    }
  }

  submitSearch(event) : void {
    if(event.keyCode == 13) this.searchText();
 } 

 removeItemFromCart(productId: number) : void {
   this.checkoutService.removeItemFromCheckout(productId);
 }

 calculateOrderTotal() : number {
   let total: number = 0;

   for(let i:number = 0; i < this._checkoutProducts.length; i++) {
     total += this._checkoutProducts[i].totalPrice;
   }

   return total;
 }
}