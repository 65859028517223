import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Promise} from 'es6-promise';
import { HttpResponse,Manufacturer } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class ManufacturerService extends BaseService {

  private _manufacturers:Array<Manufacturer> = [];
  private _frontendManufacturers:Array<Manufacturer> = [];
 
  constructor(private http: HttpClient) { 
      super();
  }

  getFrontendManufacturers(): any {
    return this.http.get(this.baseUrl + '/api/search/GetFrontendManufacturers');
  }

  getAllManufacturers(): any {
    return this.http.get(this.baseUrl + '/api/search/GetAllManufacturers');
  }

  getManufacturersCache() : Array<Manufacturer> {
    return this._manufacturers;
  }

  setManufacturersCache(manufacturers:Array<Manufacturer>) : void {
    this._manufacturers = manufacturers;
  }

  getFrontendManufacturersCache() : Array<Manufacturer> {
    return this._frontendManufacturers;
  }

  setFrontendManufacturersCache(manufacturers:Array<Manufacturer>) : void {
    this._frontendManufacturers = manufacturers;
  }
}