import { NgModule } from '@angular/core';
import { HowToBuyComponent } from './how-to-buy.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HowToBuyComponent
  ],
})
export class HowToBuyModule { }