import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Contact, Store, Navigation, Session } from '../../models';
import { StoreService } from '../../services/store.service';
import { SessionService } from '../../services/session.service';
import { BaseService } from '../../services/base.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-contact',
  styleUrls: ['./contact.component.scss'],
  templateUrl: './contact.component.html',
})
export class ContactComponent extends BaseService implements OnDestroy {

  private _store: Store = new Store();
  private _contact: Contact;
  private _contactSubmitted: boolean;
  private validName: boolean = true;
  private validEmail: boolean = true; 
  private validMessage: boolean = true;
  private logo:string = this.baseUrl + "/assets/images/logo_pequeno.jpg";
 
  private sessionSubscription: Subscription = null;
  private navigationSubscription: Subscription = null;
  
    lat: number = 38.5325381;
    lng: number = -8.8731189;

    constructor(private router: Router, private storeService: StoreService, private sessionService: SessionService) {
      super();
      this._contactSubmitted = false;
      this.storeService.getStoreInfo().then(res => this._store = res );
    }

    submitContact(): void {
      if(this.validateContact()) {
        this.storeService.submitContact(this._contact).subscribe(res => { 
          this._contactSubmitted = true;
        });
      }
    }

    validateContact(): boolean {
      let regexp:RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                                     
      this.validName = (this._contact.name != null && this._contact.name != "");
      this.validEmail = (this._contact.email != null && this._contact.email != "" && regexp.test(this._contact.email));
      this.validMessage = (this._contact.message != null && this._contact.message != "");

      return this.validName && this.validEmail && this.validMessage;
    }

    ngOnInit() {
      this._contact = new Contact();
      window.scrollTo(0,0);
      this.updateSessionInfo();
    }

    updateSessionInfo() : void {
      let session:Session = this.sessionService.getSessionInfo();
  
      if(session == null) {
        this.sessionSubscription = this.sessionService.createSessionObject().subscribe(data => {
           this.sessionService.setSessionIdentifier(data.object);
           this.updateNavigation();
        });
      }
      else {
        this.updateNavigation();
      }
    }
  
    updateNavigation() : void {
      let navigation:Navigation = new Navigation();
      navigation.page = "contact";
      this.navigationSubscription = this.sessionService.addNavigationStep(navigation).subscribe(data => { });
    }

    ngOnDestroy()
    {
      if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
      if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
    }
}