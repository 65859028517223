import { NgModule } from '@angular/core';
import { AboutUsComponent } from './about-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AboutUsComponent
  ],
})
export class AboutUsModule { }