import { SearchInput } from './searchInput';
import { SearchOutput } from './searchOutput';

export class CacheSearch {
    input: SearchInput;
    output: SearchOutput;

    constructor() {
    }
}
