import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Navigation, Session } from '../../models';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-terms-conditions',
  styleUrls: ['./terms-conditions.component.scss'],
  templateUrl: './terms-conditions.component.html',
})
export class TermsConditionsComponent implements OnDestroy {

  private sessionSubscription: Subscription = null;
  private navigationSubscription: Subscription = null;
  
  constructor(private router: Router, private sessionService: SessionService) {}

  ngOnInit() {
    window.scrollTo(0,0);
    this.updateSessionInfo();
  }

  updateSessionInfo() : void {
    let session:Session = this.sessionService.getSessionInfo();

    if(session == null) {
      this.sessionSubscription = this.sessionService.createSessionObject().subscribe(data => {
         this.sessionService.setSessionIdentifier(data.object);
         this.updateNavigation();
      });
    }
    else {
      this.updateNavigation();
    }
  }

  updateNavigation() : void {
    let navigation:Navigation = new Navigation();
    navigation.page = "terms-conditions";
    this.navigationSubscription = this.sessionService.addNavigationStep(navigation).subscribe(data => { });
  }

  ngOnDestroy()
  {
    if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
    if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
  }
}
