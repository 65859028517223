import { Component, ViewChild, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Product, SearchInput, CheckoutProduct, Category, TreeNode, Session, Navigation } from '../../models';
import { CheckoutService } from '../../services/checkout.service';
import { ProductService } from '../../services/product.service';
import { EmbedVideoService } from 'ngx-embed-video';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperNavigationInterface, SwiperPaginationInterface, SwiperKeyboardInterface, SwiperScrollbarInterface } from 'ngx-swiper-wrapper';
import { SearchService } from '../../services/search.service';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-product-detail',
  styleUrls: ['./product-detail.component.scss'],
  templateUrl: './product-detail.component.html',
})
export class ProductDetailComponent implements AfterViewInit, OnDestroy {

  private _product: Product;
  private _productCategory: Category;
  private _relatedProducts:Array<Product> = [];
  private _breadcrumb: TreeNode[] = [];
  private _quantity: number = 1;
  private _selectedProductImage: string = "";
  private formLoaded: boolean = false;

  private _mobile:boolean = false;
  private _tablet:boolean = false;
  private _pc:boolean = false;
  private _swiperPC:boolean = true;

  private sessionSubscription: Subscription = null;
  private navigationSubscription: Subscription = null;
  private breadcrumbSubscription: Subscription = null;
  private relatedProductsSubscription: Subscription = null;
  private productSubscription: Subscription = null;

  public index: number = 0;

  public scrollConfig: SwiperScrollbarInterface = {
    draggable: true,
    hide: false,
    snapOnRelease: false
  };

  public keyboardConfig: SwiperKeyboardInterface = {
    enabled: true
  };
  
  public configSwiperPC: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 5,
    centeredSlides: false,
    keyboard: this.keyboardConfig,
    scrollbar : this.scrollConfig,
    mousewheel: false,
    spaceBetween: 20,
    loop: true
  };

  public configSwiperMobile: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 4,
    centeredSlides: false,
    //keyboard: this.keyboardConfig,
    //scrollbar : this.scrollConfig,
    mousewheel: false,
    spaceBetween: 20,
    loop: false
  };

  @ViewChild(SwiperComponent) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  
  constructor(private router: Router, 
              private route: ActivatedRoute, 
              private checkoutService:CheckoutService,
              private productService: ProductService,
              private searchService: SearchService,
              private embedService: EmbedVideoService,
              private sessionService: SessionService) {
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() : void {
    this._mobile = (window.screen.width < 420);
    this._tablet = (window.screen.width >= 420 && window.screen.width < 830);
    this._pc = (window.screen.width >= 830);

    this._swiperPC = (window.screen.width >= 420);
  }
  
  ngOnInit() {

    this.checkScreenSize();
    
    this._product = new Product();

    this.route.params.subscribe(params => {
      this.getProduct(params['id']);
      window.scrollTo(0,0);
    });

    window.scrollTo(0,0);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
        this.componentRef.directiveRef.update();
      }, 3000);
  }

  getProduct(id:number) {

    let cacheProduct:Product = this.productService.getProductFromCache(id);

    if(cacheProduct == null) {
      this.productSubscription = this.productService.getProduct(id)
      .subscribe(res => {
        this._product = res.object;
        this.productService.setProductInCache(this._product);
        this.updateProductFeatures();       
      });
      
      this.breadcrumbSubscription = this.productService.getBreadcrumbInfo(id)
      .subscribe(res => {
        this._productCategory = res.object;
        this.productService.setBreadcrumbInfoInCache(this._product.id, this._productCategory);  
        this.fillBreadcrumbLayout();  
      });
    }
    else {
      this._product = cacheProduct;
      this._productCategory = this.productService.getBreadcrumbInfoFromCache(this._product.id);

      if(this._productCategory != null) {
        this.fillBreadcrumbLayout(); 
        this.updateProductFeatures();
      }
      else {
        this.breadcrumbSubscription = this.productService.getBreadcrumbInfo(id)
        .subscribe(res => {
          this._productCategory = res.object;
          this.productService.setBreadcrumbInfoInCache(this._product.id, this._productCategory);  
          this.fillBreadcrumbLayout();  
          this.updateProductFeatures();
        });
      }

    }

    this.updateSessionInfo(id);
  }

  updateProductFeatures(): void {
    
    if(this._product == null) this.router.navigate(['//pages/home']);
    if(this._product.published == false || this._product.active == false) this.router.navigate(['//pages/home']);

    if(this._product.images.length > 0) {
      this._selectedProductImage = this._product.images[0];
    }

    for (let i = 0; i < this._product.videos.length; i++) {
      this._product.videos[i].url = this.embedService.embed(this._product.videos[i].url, { query: { portrait: 0, color: '333' }, attr: { width: "100%", height: 300 } });
    }

    this.formLoaded = true;

    this.getRelatedProducts(this._product.id);
  }

  addToCheckout(): void {
    let checkoutProduct:CheckoutProduct = new CheckoutProduct();
    checkoutProduct.id = this._product.id;
    checkoutProduct.sku = this._product.sku;
    checkoutProduct.name = this._product.name;
    checkoutProduct.quantity = this._quantity;
    checkoutProduct.unitPrice = this._product.price;
    checkoutProduct.promotion = this._product.promotion;
    checkoutProduct.img = this._selectedProductImage;
    this.checkoutService.addItemToCheckout(checkoutProduct);
  }

  getRelatedProducts(productId:number): void {
    let searchInput:SearchInput = new SearchInput();
    searchInput.value = productId.toString();

    this._relatedProducts = [];
    this.relatedProductsSubscription = this.productService.getRelatedProducts(searchInput)
    .subscribe(res => this._relatedProducts = res.object.products );
  }

  showImage(img:string): void {
    this._selectedProductImage = img;
  }

  incrementQuantity(): void {
    this._quantity++;
  }

  decrementQuantity():void {
    if(this._quantity > 1) {
      this._quantity--;
    }
  }

  searchProductsByCategory(categoryId:number): void {
    this.searchService.setSearchPage(1);
    this.searchService.setSearch(1, categoryId.toString());
    this.router.navigate(['//pages/products']);
  }

  // Breadcrumb layout functions
  fillBreadcrumbLayout() : void {
    this._breadcrumb = [];
    let treeNode: TreeNode = new TreeNode();
    treeNode.id = this._productCategory.id;
    treeNode.name = this._productCategory.name;
    this._breadcrumb.push(treeNode);

    if(this._productCategory.parent != null) this.completeBreadcrumbParentInfo(this._productCategory.parent);
    this._breadcrumb = this._breadcrumb.reverse();
  }

  completeBreadcrumbParentInfo(category:Category): void {
    let treeNode: TreeNode = new TreeNode();
    treeNode.id = category.id;
    treeNode.name = category.name;
    this._breadcrumb.push(treeNode);
    if(category.parent != null) this.completeBreadcrumbParentInfo(category.parent);
  }

  updateSessionInfo(productId: number) : void {
    let session:Session = this.sessionService.getSessionInfo();

    if(session == null) {
      this.sessionSubscription = this.sessionService.createSessionObject()
      .subscribe(data => {
         this.sessionService.setSessionIdentifier(data.object);
         this.updateNavigation(productId);
      });
    }
    else {
      this.updateNavigation(productId);
    }
  }

  updateNavigation(productId: number) : void {
    let navigation:Navigation = new Navigation();
    navigation.page = "product-detail";
    navigation.productId = productId;
    this.navigationSubscription = this.sessionService.addNavigationStep(navigation)
    .subscribe(data => { });
  }

  ngOnDestroy()
  {
    if(this.productSubscription != null) this.productSubscription.unsubscribe();
    if(this.breadcrumbSubscription != null) this.breadcrumbSubscription.unsubscribe();
    if(this.relatedProductsSubscription != null) this.relatedProductsSubscription.unsubscribe();
    if(this.sessionSubscription != null) this.sessionSubscription.unsubscribe();
    if(this.navigationSubscription != null) this.navigationSubscription.unsubscribe();
  }
}
